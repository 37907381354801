// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app_app__sRM-7{
  background-color:var(--secondary);
  width: 100vw;
  height:100vh;
}
.app_container__yhzPe{
  width: 100%;
  height: 100%;

}
.app_heading__-YcuQ{
  text-align: center;
  padding: 20px;
  color: var(--primary);
}

.app_form__csLjb{
  width: 40%;
  background-color:whitesmoke;
  border-radius: 5px;
  margin:auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.app_form_control__-xxud{
  display: flex;
  align-items: center;
  gap: 10px;
}
.app_label__RwdoE{
  width: 20%;
  font-size: 18px;
  color: var(--primary);
}
.app_input__HSy5o{
  width:80%;
  height: 20px;
  padding: 10px;
  outline: none;
  border-radius: 5px;
  border: .3px solid lightgray;

}
.app_Btn__xnrqk{
  width: 80px;
  height: 30px;
  cursor: pointer;
  color: var(--secondary);
  background-color: var(--primary);
  border: none;

}`, "",{"version":3,"sources":["webpack://./src/app.module.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,YAAY;EACZ,YAAY;AACd;AACA;EACE,WAAW;EACX,YAAY;;AAEd;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,UAAU;EACV,2BAA2B;EAC3B,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;AACA;EACE,UAAU;EACV,eAAe;EACf,qBAAqB;AACvB;AACA;EACE,SAAS;EACT,YAAY;EACZ,aAAa;EACb,aAAa;EACb,kBAAkB;EAClB,4BAA4B;;AAE9B;AACA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,uBAAuB;EACvB,gCAAgC;EAChC,YAAY;;AAEd","sourcesContent":[".app{\n  background-color:var(--secondary);\n  width: 100vw;\n  height:100vh;\n}\n.container{\n  width: 100%;\n  height: 100%;\n\n}\n.heading{\n  text-align: center;\n  padding: 20px;\n  color: var(--primary);\n}\n\n.form{\n  width: 40%;\n  background-color:whitesmoke;\n  border-radius: 5px;\n  margin:auto;\n  padding: 20px;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n}\n.form_control{\n  display: flex;\n  align-items: center;\n  gap: 10px;\n}\n.label{\n  width: 20%;\n  font-size: 18px;\n  color: var(--primary);\n}\n.input{\n  width:80%;\n  height: 20px;\n  padding: 10px;\n  outline: none;\n  border-radius: 5px;\n  border: .3px solid lightgray;\n\n}\n.Btn{\n  width: 80px;\n  height: 30px;\n  cursor: pointer;\n  color: var(--secondary);\n  background-color: var(--primary);\n  border: none;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": `app_app__sRM-7`,
	"container": `app_container__yhzPe`,
	"heading": `app_heading__-YcuQ`,
	"form": `app_form__csLjb`,
	"form_control": `app_form_control__-xxud`,
	"label": `app_label__RwdoE`,
	"input": `app_input__HSy5o`,
	"Btn": `app_Btn__xnrqk`
};
export default ___CSS_LOADER_EXPORT___;
