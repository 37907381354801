import React, { useState } from "react";
import styles from "./app.module.css"
function App() {
  const [credentials, setCredentials] = useState({ name: "", email: "", roll: "" });

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(credentials);

    try {
      const response = await fetch(process.env.REACT_APP_SITE_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "no-cors",
        body: JSON.stringify({ Name: credentials.name, Email: credentials.email, Roll: credentials.roll }),
      });

      console.log(await response);

      setCredentials({ name: "", email: "", roll: "" });
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  // Check if any of the fields are empty
  const isFormValid = credentials.name && credentials.email && credentials.roll;

  return (
    <div className={styles.app}>
       <div className={styles.container}>
        <h1 className={styles.heading}>GoogleSheet Bridge</h1>
        <form className={styles.form} action="" onSubmit={handleSubmit}>
          <div className={styles.form_control}>
          <label htmlFor="name" className={styles.label}>Name</label>
          <input
           className={styles.input}
            required
            onChange={handleChange}
            type="text"
            name="name"
            value={credentials.name}
            id="name"
          />
          </div>
          <div className={styles.form_control}>
          <label htmlFor="email" className={styles.label}>Email</label>
          <input
            required
            onChange={handleChange}
            type="email"
            name="email"
            className={styles.input}
            value={credentials.email}
            id="email"
          />
        </div>
        <div className={styles.form_control}>
          <label htmlFor="roll" className={styles.label}>Roll Number</label>
          <input
            required
            className={styles.input}
            onChange={handleChange}
            type="text"
            name="roll"
            value={credentials.roll}
            id="roll"
          />
        </div>
        <button className={styles.Btn} type="submit" disabled={!isFormValid}>
          Submit
        </button>
        </form>
       </div>
    </div>
  );
}

export default App;



 /* <form onSubmit={handleSubmit}>
        <div className="form_control">
          <label htmlFor="name">Name</label>
          <input
            required
            onChange={handleChange}
            type="text"
            name="name"
            value={credentials.name}
            id="name"
          />
        </div>
     
        <div className="form_control">
          <label htmlFor="roll">Roll Number</label>
          <input
            required
            onChange={handleChange}
            type="text"
            name="roll"
            value={credentials.roll}
            id="roll"
          />
        </div>
        <button type="submit" disabled={!isFormValid}>
          Submit
        </button>
      </form> */
 